import request from "@/utils/request";
// const base = "http://tiku.cnsunrun.com/";
//放一些公用接口

//get 通用
export function httpGet(url, data, hide_loading) {
  return request({
    url: url,
    method: "get",
    params: data,
    header: {
      "X-Requested-With": "XMLHttpRequest",
      // Accept: "application/json",
      "Content-Type": "application/json; charset=UTF-8",
    },
    hide_loading: hide_loading || false,
  });
}

// post 通用
export function httpPost(url, data, hide_loading) {
  return request({
    url: url,
    method: "post",
    data: data,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      // "Accept-Language":i18n.locale == "en_US"?"en-us":'',
    },
    hide_loading: hide_loading || false,
  });
}

//通用上传图片
export function uploadImg(url, data, hide_loading) {
  return request({
    url: url,
    method: "post",
    data: data,
    transformRequest: [
      function(data) {
        // 上传对formdata 不做转化
        return data;
      },
    ],
    hide_loading: hide_loading || false,
  });
}

//参数转json,也可以去utils设置全局都用json传参
export function httpJson(url, data, hide_loading) {
  return request({
    url: url,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
    method: "post",
    data: data,
    transformRequest: [
      function(data) {
        // 对 data 进行任意转换处理
        console.log(JSON.stringify(data));
        return JSON.stringify(data);
      },
    ],
    hide_loading: hide_loading || false,
  });
}
