import { createRouter, createWebHashHistory } from "vue-router";

const index = () => import("../views/index.vue");
const login = () => import("../views/login.vue");
const notFound = () => import("../views/notFound.vue");
const productList = () => import("../views/product/productList.vue");
const addProductList = () => import("../views/product/addProductList.vue");
const addProductNumber = () => import("../views/product/addProductNumber.vue");
// const productSummary = () => import("../views/product/productSummary.vue");
const productDetail = () => import("../views/product/productDetail.vue");

const packaging = () => import("../views/product/packaging.vue");
const addPacking = () => import("../views/product/addPacking.vue");
const category = () => import("../views/product/category.vue");
const addCategory = () => import("../views/product/addCategory.vue");
const memory = () => import("../views/product/memory.vue");
const addMemory = () => import("../views/product/addMemory.vue");
const classify = () => import("../views/product/classify.vue");
const addClassify = () => import("../views/product/addClassify.vue");
const spec = () => import("../views/product/spec.vue");
const addSpec = () => import("../views/product/addSpec.vue");
const camp = () => import("../views/camp");
const addCommodity = () => import("../views/camp/addCommodity.vue");
const addEnterprise = () => import("../views/camp/addEnterprise.vue");
const addClient = () => import("../views/camp/addClient.vue");
const campproductDetail = () => import("../views/camp/campproductDetail.vue");
const campcustomerDetail = () => import("../views/camp/campcustomerDetail.vue");
const department = () => import("../views/department");
const addDepartment = () => import("../views/department/addDepartment.vue");
const job = () => import("../views/job");
const addJob = () => import("../views/job/addJob.vue");
const personnel = () => import("../views/personnel");
const addPersonnel = () => import("../views/personnel/addPersonnel.vue");
const personnelDetail = () => import("../views/personnel/personnelDetail.vue");
const customer = () => import("../views/customer");
const addCustomer = () => import("../views/customer/addCustomer.vue");
const customerDetail = () => import("../views/customer/customerDetail.vue");

const msg = () => import("../views/msg/index.vue");
const msgDetail = () => import("../views/msg/detail.vue");
const goods = () => import("../views/warehouse/goods.vue");
const addGoods = () => import("../views/warehouse/addGoods.vue");
const Batchnum = () => import("../views/warehouse/Batchnum.vue");
const BatList = () => import("../views/warehouse/BatList.vue");
const BatEdit = () => import("../views/warehouse/BatEdit.vue");
const financial = () => import("../views/financial");
const blitem = () => import("../views/keeper/blitem.vue");
const blitemDetail = () => import("../views/keeper/blitemDetail.vue");
const breakage = () => import("../views/keeper/breakage.vue");
const breakageDetail = () => import("../views/keeper/breakageDetail.vue");

const discontinued = () => import("../views/keeper/discontinued.vue");
const inventory = () => import("../views/keeper/inventory.vue");
const addBlitem = () => import("../views/keeper/addBlitem.vue");
const addBreakage = () => import("../views/keeper/addBreakage.vue");
const addDiscontinued = () => import("../views/keeper/addDiscontinued.vue");
const entrepot = () => import("../views/entrepot");
const addTemperature = () => import("../views/entrepot/addTemperature.vue");
const temperatureDetail = () => import("../views/entrepot/temperatureDetail.vue");
const salesPlan = () => import("../views/sales/salesPlan.vue");
const addPlan = () => import("../views/sales/addPlan.vue");
const priceSheet = () => import("../views/sales/priceSheet.vue");
const addSheet = () => import("../views/sales/addSheet.vue");
const sheetDetails = () => import("../views/sales/sheetDetails.vue");
const addsalesOut = () => import("../views/sales/addsalesOut.vue");
const addsalesBack = () => import("../views/sales/addsalesBack.vue");
const planDetails = () => import("../views/sales/planDetails.vue");
const salesOut = () => import("../views/sales/salesOut.vue");
const salesBack = () => import("../views/sales/salesBack.vue");
const planOutDetails = () => import("../views/sales/planOutDetails.vue");
const salesPut = () => import("../views/sales/salesPut.vue");
const planPutDetails = () => import("../views/sales/planPutDetails.vue");
const purchasePlan = () => import("../views/purchase/purchasePlan.vue");
const addpurchasePlan = () => import("../views/purchase/addpurchasePlan.vue");
const addpurchaseAuto = () => import("../views/purchase/addpurchaseAuto.vue");
const addpurchaseAccept = () => import("../views/purchase/addpurchaseAccept.vue");
const addpurchasePut = () => import("../views/purchase/addpurchasePut.vue");
const addpurchaseBack = () => import("../views/purchase/addpurchaseBack.vue");
const purchaseAccept = () => import("../views/purchase/purchaseAccept.vue");
const purchaseAcceptDetail = () => import("../views/purchase/purchaseAcceptDetail.vue");
const purchasePutDetail = () => import("../views/purchase/purchasePutDetail.vue");
const purchasePlanDetail = () => import("../views/purchase/purchasePlanDetail.vue");
const purchaseBackDetail = () => import("../views/purchase/purchaseBackDetail.vue");
const purchasePut = () => import("../views/purchase/purchasePut.vue");
const purchaseBack = () => import("../views/purchase/purchaseBack.vue");
const purchaseSlect = () => import("../views/purchase/purchaseSlect.vue");
const purchaseSlectDetail = () => import("../views/purchase/purchaseSlectDetail.vue");
const purchaseSlectDetail2 = () => import("../views/purchase/purchaseSlectDetail2.vue");
const purchaseSlectDetail3 = () => import("../views/purchase/purchaseSlectDetail3.vue");
const purchaseSlectDetail4 = () => import("../views/purchase/purchaseSlectDetail4.vue");
const salesSlect = () => import("../views/sales/salesSlect.vue");

const system = () => import("../views/system/system.vue");
const addItem = () => import("../views/system/addItem.vue");
const apiSettings = () => import("../views/system/apiSettings.vue");
const addApiSettings = () => import("../views/system/addApiSettings.vue");
const address = () => import("../views/address");
const addAddressItem = () => import("../views/address/addItem.vue");
const menu = () => import("../views/menu");
const addmenuItem = () => import("../views/menu/addItem.vue");
const routes = [
  // 主页面
  {
    path: "/",
    name: "index",
    component: index,
    redirect: "/salesPlan",
    children: [
      //采购管理
      {
        path: "/purchasePlan",
        name: "purchasePlan",
        component: purchasePlan,
        meta: {
          name: "/purchasePlan",
          title: ["采购管理", "采购计划单"],
        },
      },
      {
        path: "/addpurchasePlan",
        name: "addpurchasePlan",
        component: addpurchasePlan,
        meta: {
          name: "/purchasePlan",
          title: ["采购管理", "采购计划单", "新增采购计划"],
        },
      },
      {
        path: "/addpurchaseAuto",
        name: "addpurchaseAuto",
        component: addpurchaseAuto,
        meta: {
          name: "/purchaseAuto",
          title: ["采购管理", "一键入库", "新增一键入库"],
        },
      },
      {
        path: "/addpurchaseAccept",
        name: "addpurchaseAccept",
        component: addpurchaseAccept,
        meta: {
          name: "/purchaseAccept",
          title: ["采购管理", "采购验收单", "填写验收单信息"],
        },
      },
      {
        path: "/purchaseAcceptDetail",
        name: "purchaseAcceptDetail",
        component: purchaseAcceptDetail,
        meta: {
          name: "/purchaseAccept",
          title: ["采购管理", "采购验收单", "验收单详情"],
        },
      },
      {
        path: "/purchasePlanDetail",
        name: "purchasePlanDetail",
        component: purchasePlanDetail,
        meta: {
          name: "/purchasePlan",
          title: ["采购管理", "采购计划单", "计划单详情"],
        },
      },
      {
        path: "/purchaseBackDetail",
        name: "purchaseBackDetail",
        component: purchaseBackDetail,
        meta: {
          name: "/purchaseBack",
          title: ["采购管理", "采购退货单", "退货单详情"],
        },
      },
      {
        path: "/purchasePutDetail",
        name: "purchasePutDetail",
        component: purchasePutDetail,
        meta: {
          name: "/purchasePut",
          title: ["采购管理", "采购入库单", "入库单详情"],
        },
      },
      {
        path: "/purchaseAccept",
        name: "purchaseAccept",
        component: purchaseAccept,
        meta: {
          name: "/purchaseAccept",
          title: ["采购管理", "采购验收单"],
        },
      },
      {
        path: "/purchasePut",
        name: "purchasePut",
        component: purchasePut,
        meta: {
          name: "/purchasePut",
          title: ["采购管理", "采购入库单"],
        },
      },
      {
        path: "/addpurchasePut",
        name: "addpurchasePut",
        component: addpurchasePut,
        meta: {
          name: "/purchasePut",
          title: ["采购管理", "采购入库单", "填写入库单信息"],
        },
      },
      {
        path: "/purchaseBack",
        name: "purchaseBack",
        component: purchaseBack,
        meta: {
          name: "/purchaseBack",
          title: ["采购管理", "采购退货单"],
        },
      },
      {
        path: "/addpurchaseBack",
        name: "addpurchaseBack",
        component: addpurchaseBack,
        meta: {
          name: "/purchaseBack",
          title: ["采购管理", "采购退货单", "填写退货单信息"],
        },
      },
      {
        path: "/purchaseSlect",
        name: "purchaseSlect",
        component: purchaseSlect,
        meta: {
          name: "/purchaseSlect",
          title: ["采购管理", "采购业务查询"],
        },
      },
      {
        path: "/purchaseSlectDetail",
        name: "purchaseSlectDetail",
        component: purchaseSlectDetail,
        meta: {
          name: "/purchaseSlect",
          title: ["采购管理","采购业务查询", "采购计划单明细详情"],
        },
      },
      {
        path: "/purchaseSlectDetail2",
        name: "purchaseSlectDetail2",
        component: purchaseSlectDetail2,
        meta: {
          name: "/purchaseSlect",
          title: ["采购管理", "采购业务查询","采购验收单明细详情"],
        },
      },
      {
        path: "/purchaseSlectDetail3",
        name: "purchaseSlectDetail3",
        component: purchaseSlectDetail3,
        meta: {
          name: "/purchaseSlect",
          title: ["采购管理","采购业务查询", "采购入库单明细详情"],
        },
      },
      {
        path: "/purchaseSlectDetail4",
        name: "purchaseSlectDetail4",
        component: purchaseSlectDetail4,
        meta: {
          name: "/purchaseSlect",
          title: ["采购管理","采购业务查询", "采购退货单明细详情"],
        },
      },
      //销售管理
      {
        path: "/salesPlan",
        name: "salesPlan",
        component: salesPlan,
        meta: {
          name: "/salesPlan",
          title: ["销售管理", "销售计划单"],
        },
      },
      {
        path: "/addPlan",
        name: "addPlan",
        component: addPlan,
        meta: {
          name: "/salesPlan",
          title: ["销售管理", "销售计划单", "新增销售计划"],
        },
      },
      {
        path: "/priceSheet",
        name: "priceSheet",
        component: priceSheet,
        meta: {
          name: "/priceSheet",
          title: ["销售管理", "报价单"],
        },
      },
      {
        path: "/addSheet",
        name: "addSheet",
        component: addSheet,
        meta: {
          name: "/addSheet",
          title: ["销售管理", "报价单", "新增报价单"],
        },
      },
      {
        path: "/sheetDetails",
        name: "sheetDetails",
        component: sheetDetails,
        meta: {
          name: "/sheetDetails",
          title: ["销售管理", "报价单", "报价单详情"],
        },
      },
      {
        path: "/planDetails",
        name: "planDetails",
        component: planDetails,
        meta: {
          name: "/salesPlan",
          title: ["销售管理", "销售出库单", "销售计划单详情"],
        },
      },
      {
        path: "/salesOut",
        name: "salesOut",
        component: salesOut,
        meta: {
          name: "/salesOut",
          title: ["销售管理", "销售出库单"],
        },
      },
      {
        path: "/addsalesOut",
        name: "addsalesOut",
        component: addsalesOut,
        meta: {
          name: "/salesOut",
          title: ["销售管理", "销售出库单", "新增销售出库单"],
        },
      },
      {
        path: "/salesBack",
        name: "salesBack",
        component: salesBack,
        meta: {
          name: "/salesBack",
          title: ["销售管理", "销售退回计划"],
        },
      },
      {
        path: "/addsalesBack",
        name: "addsalesBack",
        component: addsalesBack,
        meta: {
          name: "/salesBack",
          title: ["销售管理", "销售退回计划", "新增销售退回单"],
        },
      },
      {
        path: "/planOutDetails",
        name: "planOutDetails",
        component: planOutDetails,
        meta: {
          name: "/salesOut",
          title: ["销售管理", "销售出库单", "销售出库单详情"],
        },
      },
      {
        path: "/salesPut",
        name: "salesPut",
        component: salesPut,
        meta: {
          name: "/salesPut",
          title: ["库管业务", "产品账页"],
        },
      },
      {
        path: "/salesSlect",
        name: "salesSlect",
        component: salesSlect,
        meta: {
          name: "/salesSlect",
          title: ["销售管理", "销售业务查询"],
        },
      },
      
      
      {
        path: "/planPutDetails",
        name: "planPutDetails",
        component: planPutDetails,
        meta: {
          name: "/salesBack",
          title: ["销售管理", "销售退回单", "销售退回单详情"],
        },
      },
        // 消息
        {
          path: "/msg",
          name: "msg",
          component: msg,
          meta: {
            name: "/msg",
            title: ["系统消息", "消息列表"],
          },
        },
        {
          path: "/msgDetail",
          name: "msgDetail",
          component: msgDetail,
          meta: {
            name: "/msg",
            title: ["系统消息", "消息详情"],
          },
        },
      // 产品管理
      {
        path: "/productList",
        name: "productList",
        component: productList,
        meta: {
          name: "/productList",
          title: ["产品管理", "产品列表"],
        },
      },
      // {
      //   path: "/productSummary",
      //   name: "productSummary",
      //   component: productSummary,
      //   meta: {
      //     name: "/productSummary",
      //     title: ["产品管理", "产品汇总"],
      //   },
      // },
      {
        path: "/addProductList",
        name: "addProductList",
        component: addProductList,
        meta: {
          name: "/productList",
          title: ["产品管理", "产品列表", "新增产品"],
        },
      },
      {
        path: "/addProductNumber",
        name: "addProductNumber",
        component: addProductNumber,
        meta: {
          name: "/productList",
          title: ["产品管理", "产品列表", "编辑产品编号"],
        },
      },
      {
        path: "/productDetail",
        name: "productDetail",
        component: productDetail,
        meta: {
          name: "/productList",
          title: ["产品管理", "产品列表", "产品详情"],
        },
      },
      {
        path: "/packaging",
        name: "packaging",
        component: packaging,
        meta: { name: "/packaging", title: ["产品管理", "包装单位"] },
      },
      {
        path: "/addPacking",
        name: "addPacking",
        component: addPacking,
        meta: {
          name: "/packaging",
          title: ["产品管理", "包装单位", "新增单位"],
        },
      },
      {
        path: "/category",
        name: "category",
        component: category,
        meta: { name: "/category", title: ["产品管理", "类别管理"] },
      },
      {
        path: "/addCategory",
        name: "addCategory",
        component: addCategory,
        meta: {
          name: "/category",
          title: ["产品管理", "类别管理", "新增类别"],
        },
      },
      {
        path: "/memory",
        name: "memory",
        component: memory,
        meta: { name: "/memory", title: ["产品管理", "储存管理"] },
      },
      {
        path: "/addMemory",
        name: "addMemory",
        component: addMemory,
        meta: {
          name: "/memory",
          title: ["产品管理", "储存管理", "新增储存条件"],
        },
      },
      {
        path: "/classify",
        name: "classify",
        component: classify,
        meta: { name: "/classify", title: ["产品管理", "分类管理"] },
      },
      {
        path: "/addClassify",
        name: "addClassify",
        component: addClassify,
        meta: {
          name: "/classify",
          title: ["产品管理", "分类管理", "新增分类"],
        },
      },
      {
        path: "/spec",
        name: "spec",
        component: spec,
        meta: { name: "/spec", title: ["产品管理", "规格管理"] },
      },
      {
        path: "/addSpec",
        name: "addSpec",
        component: addSpec,
        meta: {
          name: "/spec",
          title: ["产品管理", "规格管理", "新增规格"],
        },
      },
      //首营
      {
        path: "/camp",
        name: "camp",
        component: camp,
        meta: {
          name: "/camp",
          title: ["首营管理"],
        },
      },
      {
        path: "/addCommodity",
        name: "addCommodity",
        component: addCommodity,
        meta: {
          name: "/camp",
          title: ["首营管理","首营产品审批", "新增"],
        },
      },
      {
        path: "/addEnterprise",
        name: "addEnterprise",
        component: addEnterprise,
        meta: {
          name: "/camp",
          title: ["首营管理","首营企业审批", "新增"],
        },
      },
      {
        path: "/addClient",
        name: "addClient",
        component: addClient,
        meta: {
          name: "/camp",
          title: ["首营管理","首营客户审批", "新增"],
        },
      },
      {
        path: "/campproductDetail",
        name: "campproductDetail",
        component: campproductDetail,
        meta: {
          name: "/camp",
          title: ["首营管理","首营产品审批", "详情"],
        },
      },
      {
        path: "/campcustomerDetail",
        name: "campcustomerDetail",
        component: campcustomerDetail,
        meta: {
          name: "/camp",
          title: ["首营管理","首营企业/客户审批", "详情"],
        },
      },
      // 部门管理
      {
        path: "/department",
        name: "department",
        component: department,
        meta: {
          name: "/department",
          title: ["部门管理"],
        },
      },
      {
        path: "/addDepartment",
        name: "addDepartment",
        component: addDepartment,
        meta: {
          name: "/department",
          title: ["部门管理", "新增部门"],
        },
      },
      // 系统管理
      {
        path: "/system",
        name: "system",
        component: system,
        meta: {
          name: "/system",
          title: ["系统设置","系统配置"],
        },
      },
      {
        path: "/addItem",
        name: "addItem",
        component: addItem,
        meta: {
          name: "/system",
          title: ["系统设置", "系统配置","新增"],
        },
      },
      {
        path: "/apiSettings",
        name: "apiSettings",
        component: apiSettings,
        meta: {
          name: "/apiSettings",
          title: ["系统设置", "Api配置"],
        },
      },
      {
        path: "/addApiSettings",
        name: "addApiSettings",
        component: addApiSettings,
        meta: {
          name: "/apiSettings",
          title: ["系统设置", "Api配置","新增"],
        },
      },
      // 地址管理
      {
        path: "/address",
        name: "address",
        component: address,
        meta: {
          name: "/address",
          title: ["地址管理"],
        },
      },
      {
        path: "/addAddressItem",
        name: "addAddressItem",
        component: addAddressItem,
        meta: {
          name: "/address",
          title: ["地址管理", "新增"],
        },
      },
      // 菜单管理
        {
          path: "/menu",
          name: "menu",
          component: menu,
          meta: {
            name: "/menu",
            title: ["菜单设置"],
          },
        },
        {
          path: "/addmenuItem",
          name: "addImenutem",
          component: addmenuItem,
          meta: {
            name: "/menu",
            title: ["菜单设置", "新增"],
          },
        },
      // 职位管理
      {
        path: "/job",
        name: "job",
        component: job,
        meta: {
          name: "/job",
          title: ["职位管理"],
        },
      },
      {
        path: "/addJob",
        name: "addJob",
        component: addJob,
        meta: {
          name: "/job",
          title: ["职位管理", "新增职位"],
        },
      },
      // 人员管理
      {
        path: "/personnel",
        name: "personnel",
        component: personnel,
        meta: {
          name: "/personnel",
          title: ["人员管理"],
        },
      },
      {
        path: "/addPersonnel",
        name: "addPersonnel",
        component: addPersonnel,
        meta: {
          name: "/personnel",
          title: ["人员管理", "新增人员"],
        },
      },
      {
        path: "/personnelDetail",
        name: "personnelDetail",
        component: personnelDetail,
        meta: {
          name: "/personnel",
          title: ["人员管理", "人员详情"],
        },
      },
      // 客户供应商管理
      {
        path: "/customer",
        name: "customer",
        component: customer,
        meta: {
          name: "/customer",
          title: ["客户供应商管理"],
        },
      },
      {
        path: "/addCustomer",
        name: "addCustomer",
        component: addCustomer,
        meta: {
          name: "/customer",
          title: ["客户供应商管理", "新增客户供应商"],
        },
      },
      {
        path: "/customerDetail",
        name: "customerDetail",
        component: customerDetail,
        meta: {
          name: "/customer",
          title: ["客户供应商管理", "客户供应商详情"],
        },
      },
      // 货位仓库
      // redirect: "/productList",
      {
        path: "/goods",
        name: "goods",
        component: goods,
        meta: {
          name: "/goods",
          title: ["货位仓库", "货位管理"],
        },
      },
      {
        path: "/addGoods",
        name: "addGoods",
        component: addGoods,
        meta: {
          name: "/goods",
          title: ["货位仓库", "货位管理", "新增货位"],
        },
      },
      {
        path: "/Batchnum",
        name: "Batchnum",
        component: Batchnum,
        meta: {
          name: "/Batchnum",
          title: ["货位仓库", "货位产品批号效期修改"],
        },
      },
      {
        path: "/BatList",
        name: "BatList",
        component: BatList,
        meta: {
          name: "/Batchnum",
          title: ["货位仓库", "货位产品批号效期修改", "批号列表"],
        },
      },
      {
        path: "/BatEdit",
        name: "BatEdit",
        component: BatEdit,
        meta: {
          name: "/Batchnum",
          title: ["货位仓库", "货位产品批号效期修改", "批号列表", "批号修改"],
        },
      },

      // 辅助财力
      {
        path: "/financial",
        name: "financial",
        component: financial,
        meta: {
          name: "/financial",
          title: ["辅助财务"],
        },
      },
      //库管业务
      {
        path: "/blitem",
        name: "blitem",
        component: blitem,
        meta: {
          name: "/blitem",
          title: ["库管业务", "盘点单"],
        },
      },
      {
        path: "/blitemDetail",
        name: "blitemDetail",
        component: blitemDetail,
        meta: {
          name: "/blitem",
          title: ["库管业务", "盘点单","查看详情"],
        },
      },
      
      {
        path: "/addBlitem",
        name: "addBlitem",
        component: addBlitem,
        meta: {
          name: "/blitem",
          title: ["库管业务", "盘点单", "新增盘点单"],
        },
      },
      {
        path: "/breakage",
        name: "breakage",
        component: breakage,
        meta: {
          name: "/breakage",
          title: ["库管业务", "报损报益管理"],
        },
      },
      {
        path: "/breakageDetail",
        name: "breakageDetail",
        component: breakageDetail,
        meta: {
          name: "/breakage",
          title: ["库管业务", "报损报益管理","查看详情"],
        },
      },
      {
        path: "/addBreakage",
        name: "addBreakage",
        component: addBreakage,
        meta: {
          name: "/breakage",
          title: ["库管业务", "报损报益管理", "新增报损报溢管理"],
        },
      },
      {
        path: "/discontinued",
        name: "discontinued",
        component: discontinued,
        meta: {
          name: "/discontinued",
          title: ["库管业务", "停售产品单"],
        },
      },
      {
        path: "/addDiscontinued",
        name: "addDiscontinued",
        component: addDiscontinued,
        meta: {
          name: "/discontinued",
          title: ["库管业务", "停售产品单", "新增停售产品单"],
        },
      },
      {
        path: "/inventory",
        name: "inventory",
        component: inventory,
        meta: {
          name: "/inventory",
          title: ["库管业务", "库存查询"],
        },
      },

      //仓库养护
      {
        path: "/entrepot",
        name: "entrepot",
        component: entrepot,
        meta: {
          name: "/entrepot",
          title: ["仓库养护"],
        },
      },
      {
        path: "/addTemperature",
        name: "addTemperature",
        component: addTemperature,
        meta: {
          name: "/entrepot",
          title: ["仓库养护", "添加仓库温湿度登记表"],
        },
      },
      {
        path: "/temperatureDetail",
        name: "temperatureDetail",
        component: temperatureDetail,
        meta: {
          name: "/entrepot",
          title: ["仓库养护", "添加仓库温湿度详情"],
        },
      },
    ],
  },

  // 登录
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      name: "/login",
      title: ["登录"],
    },
  },
  {
    path: "/:catchAll(.*)",
    name: "notFound",
    component: notFound,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});
export default router;
